<template>
    <div class="mst">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ManagerSubTitle"
    }
</script>

<style scoped>
    .mst{
        border-radius: 5px;
        line-height: 30px;
        background-color: #aacdfd;
        padding-left: 5px;
        color: #000000;
        font-weight: bold;
        font-size: 15px;
    }

</style>